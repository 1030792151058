.wave_classes {
  position: absolute;
  left: 0;
  top: -3.7rem !important;
  z-index: 1;
  width: 100%;
}

.search_header {
  width: 100%;
  min-height: 60vh;
  padding: 4rem 4rem;
  background-color: #000727;
  position: relative;
}

.box-selects,
.box-selects-button {
  z-index: 100;
}

@media screen and (max-width: 1460px) {
  .search_header {
    min-height: 75vh;
    padding: 4.3rem 4rem;
  }
}

@media screen and (max-width: 960px) {
  .search_header {
    min-height: 75vh;
    padding: 4.3rem 4rem;
  }
}

@media screen and (max-width: 410px) {
  .search_header {
    padding: 2.3rem 1rem;
  }

  .line_class {
    width: 14rem;
    height: 0.5rem;
    z-index: 100 !important;
  }

  .search_header {
    min-height: 78vh;
  }
}

@media screen and (max-width: 370px) {
  .search_header {
    padding: 2.3rem 1rem;
  }

  .line_class {
    width: 14rem;
    height: 0.5rem;
    z-index: 100 !important;
  }
}
