.container-services{
    width: 100%;
    min-height: 100vh;
    overflow: hidden;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    gap: 8rem;
}

.webGlScene{
    position: relatve;
}

.container-text-services{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 1.2rem;
    flex-direction: column;
}

.box-services-items{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    height: 100%;
    gap: 3.5rem;
    width: 22vw;
}

.title-service{
    font-size: 1.6rem;
    color: #317dd3;
}

.text-service{
    color: #f5f5f5;
    font-size: 1.1rem;
    letter-spacing: .4px;
}

.webGlScene{
    filter: contrast(108%) brightness(105%);
}


@media screen and (max-width:1400px) {
    .container-services{
        gap:2rem;
        margin-top: 4rem;
    }

    .box-services-items{
        width: 28vw;
        gap: 3rem;
    }

    .title-service{
        font-size: 1.3rem;
        color: #317dd3;
    }

    .text-service{
        color: #f5f5f5;
        font-size: .95rem;
        letter-spacing: .4px;
    }
    
}

  
@media screen and (max-width:1100px) {
    .container-services{
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 6rem;
        margin-top: 6rem;
    }

    .container-text-services{
        align-items: center;
        gap: 1rem;
    }

    .box-services-items{
        width: 60vw;
        justify-content: center;
        gap: 6rem;
        text-align: center;
    }
    
}


@media screen and (max-width:700px) {
    .container-services{
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
        gap: 4rem;
        margin-top: 6rem;
        text-align: start;
        
    }
    .container-text-services{
        align-items:  flex-start;
        gap: 1rem;
    }

    .box-services-items{
        width: 90vw;
        justify-content: center;
        padding: 0 1.3rem;
        gap: 4rem;
        text-align: start;
    }
    
}