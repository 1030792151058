/* bottom */
.parallax > use {
  animation: move-forever 8s linear infinite;
}
.parallax > use:nth-child(1) {
  animation-delay: -2s;
}

@keyframes move-forever {
  0% {
    transform: translate(-90px, 0%);
  }
  100% {
    transform: translate(85px, 0%);
  }
}
.editorial {
  position: absolute;
  bottom: 0;
  display: block;
  width: 100%;
  height: 5em;
  max-height: 100vh;
  margin: 0;
}

@media screen and (max-width: 610px) {
  .editorial {
    width: 180%;
    height: 4rem;
  }
}
