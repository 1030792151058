.banner-presentation {
  background: url("../../../../public/images/backgrounds/surfGirl.png");
  width: 100%;
  height: 80vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center ;
  position: relative;
  filter: contrast(110%) brightness(110%);
  transition: all 0.5s ease;
}

/* Efecto de hover para la imagen de fondo */
.banner-presentation:hover {
  filter: contrast(120%) brightness(105%);
}

/* Estilos para pantallas medianas y pequeñas */
@media (max-width: 768px) {
  .banner-presentation {
    height: 50vh;  
  }
}

/* Animaciones sutiles */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.text-blue-800, h1, h2, h3 {
  animation: fadeIn 0.8s ease-out forwards;
}

/* Estilos para elementos con hover */
.transition-all {
  transition: all 0.3s ease-in-out;
}

/* Sombras suaves */
.shadow-xl {
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
}

.hover\:shadow-2xl:hover {
  box-shadow: 0 15px 35px rgba(0, 0, 0, 0.15);
}


