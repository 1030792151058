.container_home {
  width: 100%;
  height: 92vh;
  padding: 4rem 8rem;
  position: relative;
  z-index: 210 !important;
}

.wave {
  position: absolute;
  bottom: -1rem;
  width: 100%;
  left: 0;
}

.box-text-hero {
  line-height: 5rem;
}

.box-text-subHero {
  line-height: 2.8rem;
}

.text-hero {
  font-size: 3.5rem;
  letter-spacing: 2px;
}

.text-subHero {
  font-size: 2.4rem;
  letter-spacing: 0.2px;
}

/* effect #laNuevaOla */

.text-wave {
  font-size: 4.5rem;
  font-weight: 900;
  letter-spacing: 3px;
  text-transform: uppercase;
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 800 88.7'%3E%3Cpath d='M800 56.9c-155.5 0-204.9-50-405.5-49.9-200 0-250 49.9-394.5 49.9v31.8h800v-.2-31.6z' fill='%23629cff'/%3E%3C/svg%3E");
  background-size: 198% 198%;
  background-position-y: 10%;
  background-position-x: -198%;
  background-repeat: repeat;
  line-height: 4rem;
  background-color: rgb(255, 255, 255);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: wave 3s linear infinite;
}


@keyframes wave {
  0% {
    background-position-x: -560px;
  }
  100% {
    background-position-x: 560px;
  }
}

.line {
  width: 5rem;
  background-color: #4a95dbbe;
  height: 0.6rem;
  border-radius: 2px;
  position: relative;
  margin-top: 0.6rem;
}

/*  */

.box {
  position: absolute;
  top: 7rem;
  right: 12%;
  width: 58vmin;
  height: 58vmin;
}

.box::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  border-radius: 50%;
  border: 1px dashed rgba(255, 255, 255, 0.4);
  transform: scale(1.27);
  animation: spin2 9s ease-in-out infinite alternate;
}

.spin-container {
  width: 100%;
  height: 100%;
  animation: spin 12s ease-in-out infinite alternate;
  position: relative;
}

.shape {
  width: 100%;
  height: 100%;
  transition: border-radius 1s ease-out;
  border-radius: 60% 40% 30% 70% / 60% 30% 70% 40%;
  animation: morph 5s ease-in-out infinite both alternate;
  position: absolute;
  overflow: hidden;
  z-index: 5;
}

.bd {
  width: 142%;
  height: 142%;
  position: absolute;
  left: -21%;
  top: -21%;
  background: url("../../../../public/images/backgrounds/imgPresentation.jpeg");
  background-size: 110%;
  background-position: center center;
  display: flex;
  font-weight: bold;
  align-items: center;
  justify-content: center;
  animation: spin 12s ease-in-out infinite alternate-reverse;
  filter: contrast(110%);
  z-index: 2;
}

@keyframes morph {
  0% {
    border-radius: 40% 60% 60% 40% / 60% 30% 70% 40%;
  }
  100% {
    border-radius: 40% 60%;
  }
}

@keyframes spin {
  to {
    transform: rotate(1turn);
  }
}

@keyframes spin2 {
  to {
    transform: rotate(1turn);
    border-color: rgba(139, 183, 240, 0.582);
  }
}

@media screen and (max-width: 1500px) {
  .box {
    position: absolute;
    top: 5rem;
    right: 5%;
    width: 52vmin;
    height: 52vmin;
  }

  .box::before {
    content: "";
    transform: scale(1.22);
  }

  .box-text-hero {
    line-height: 4rem;
  }

  .text-subHero{
    font-size: 1.8rem;
  }

  .text-hero{
    font-size: 3rem;
  }

  .text-wave {
    font-size: 4rem;
  }
  
  
  @keyframes wave {
    0% {
      background-position-x: -520px;
    }
    100% {
      background-position-x: 520px;
    }
  }
  
}

@media screen and (max-width: 1480px) {
  .container_home {
    padding: 2rem 4rem;
    z-index: 210 !important;
  }

  .box-selects {
    max-width: 33rem;
  }

  .text-subHero {
    font-size: 1.6rem;
    line-height: 110%;
  }
}

@media screen and (max-width: 1400px) {
  .box {
    position: absolute;
    top: 5rem;
    right: 5%;
    width: 65vmin;
    height: 60vmin;
  }
}

@media screen and (max-width: 1280px) {
  .container_home {
    padding: 1.5rem 2rem;
  }

  .box {
    position: absolute;
    top: 8rem;
    right: 5%;
    width: 50vmin;
    height: 50vmin;
  }

  .box ::before {
    content: "";
    transform: scale(1.12);
  }

  .box-selects {
    max-width: 30rem;
  }
}

@media screen and (max-width: 1100px) {
  .container_home {
    padding: 3rem 0.4rem !important;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    height: 90vh;
    position: relative;
  }

  .wave {
    bottom: 0rem;
  }

  .box {
    display: none;
  }

    
  @keyframes wave {
    0% {
      background-position-x: -450px;
    }
    100% {
      background-position-x: 450px;
    }
  }

  .text-hero {
    font-size: 2.7rem;
    line-height: 3.5rem;
    padding: 0rem .5rem;
  }

  .text-subHero {
    margin-top: 0.4rem;
    font-size: 1.9rem;
    line-height: 2.3rem;
    padding-left: .5rem;
  }
  .line {
    height: 0.4rem;
  }
  .text-wave {
    font-size: 3.5rem;
    letter-spacing: 1.2px;
    transition: 3s;
    transition-delay: 2s;
    animation: wave 4s linear infinite;
    padding: 0rem .5rem;
  }
}

@media screen and (max-width: 600px) {
  .container_home {
    padding: 2rem 0.4rem !important;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    height: 90vh;
    position: relative;
    z-index: 210 !important;
  }

  .wave {
    bottom: 0rem;
  }

  .text-hero {
    font-size: 2.2rem;
    padding: 0rem 0rem;
  }

  .text-subHero {
    padding: 0rem 0rem;
  }

  .text-wave {
    font-size: 2.8rem;
    line-height: 2.2rem;
    letter-spacing: 1.2px;
    transition: 3s;
    transition-delay: 2s;
    animation: wave 6s linear infinite;
    padding: 0rem 0rem;
    background-size: 140% 140%;
    background-position-y: 1px;
    background-position-x: -220%;
  }

  @keyframes wave {
    0% {
      background-position-x: -520px;
    }
    100% {
      background-position-x: 520px;
    }
  }
}

@media screen and (max-width: 405px) {
  .container_home {
    padding: 2rem 1rem;
  }

  .wave {
    bottom: 0rem;
  }

  .text-hero {
    font-size: 2rem;
    line-height: 3.4rem;
    padding: 0rem 0rem;
  }

  .text-subHero {
    margin-top: 0.4rem;
    font-size: 1.7rem;
    padding: 0rem 0rem;
    line-height: 1.8rem;
  }
  .line {
    height: 0.4rem;
  }
  .text-wave {
    font-size: 2.3em;
    line-height: 2rem;
    letter-spacing: 1.2px;
    transition: 3s;
    padding: 0rem 0rem;
    transition-delay: 2s;
    animation: wave 4s linear infinite;
  }
}

@media screen and (max-height: 800px) {
  .container_home {
    width: 100%;
    padding: 2rem 4rem;
    position: relative;
    z-index: 0;
  }
}